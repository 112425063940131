import React, { useState, useEffect } from "react";
import { scroller } from "react-scroll"
import { Grid, Button, Typography, useMediaQuery, TextField } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ColorExtractor } from 'react-color-extractor'
import illustrationHero from "../../images/illustration-hero.svg";
import Questions from '../Questions/Questions'
import { GetColorName } from 'hex-color-to-color-name'

const IMAGE_ONE = "https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80";

const IMAGE = IMAGE_ONE;
const SWATCHES_STYLES = {
  marginTop: 20,
  display: "flex",
  justifyContent: "center"
};

const useStyles = makeStyles((theme) => ({
  illustrationHero: {
    width: "80%",
    zIndex: 1,
    margin: "0 auto",
    paddingTop: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "2em",
    },
    backgroundSize: 'cover'

  },
  illustrationHeroBackDiv: {
    width: "70%",
    height: "50%",
    backgroundColor: theme.palette.common.SoftBlue,
    position: "relative",
    top: "-50%",
    borderBottomLeftRadius: 250,
    marginLeft: "auto",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      top: "-30%",
      height: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "-30%",
      height: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      top: "-50%",
      height: "50%",
    },
  },
  heroText: {
    padding: "5em",
    [theme.breakpoints.down("sm")]: {
      padding: "2em",
      marginTop: "2em",
    },
  },
  chromeButton: {
    textTransform: "none",
    backgroundColor: theme.palette.common.SoftBlue,
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      borderColor: theme.palette.common.SoftBlue,
      color: theme.palette.common.SoftBlue,
    },
  },
  firefoxButton: {
    textTransform: "none",
    "&:hover": {
      borderColor: "black",
    },
  },
  heroTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      textAlign: "center",
    },
  },
  heroSubtitle: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      maxWidth: "80%",
      margin: "1em auto",
      fontSize: ".75rem",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
}));
//
//
//
// HeroBlock component
function HeroBlock() {
  const [imageFile, setImageFile] = useState('')

  useEffect(() => {

  }, [imageFile])
  const [colors, setColors] = useState([])

  const getColors = values => setColors(values)

  const renderSwatches = () => {

    return colors.map((color, id) => {
      const colorName = GetColorName(color)
      console.log(colorName)
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div
            key={id}
            style={{
              backgroundColor: color,
              width: 100,
              height: 100,
              borderRadius: '50%',
            }}
          />
          <TextField size={10} variant="outlined" value={color} />
          <TextField size={10} variant="outlined" value={colorName} />
        </div>
      );
    });
  };

  const classes = useStyles();
  //
  //
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollMe = () => {
    scroller.scrollTo("footer", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  const imgChange = e => {

    //setavatarUrl(URL.createObjectURL(e.target.files[0]))
    setImageFile(window.URL.createObjectURL(e.target.files[0]))
    scroller.scrollTo("values", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  const handleChange = e => {
    setImageFile(e.target.value)

    scroller.scrollTo("values", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });

  }

  // JSX
  return (
    <>
      <Grid container wrap="wrap-reverse">
        {/* ---hero text--- */}
        <Grid item xs={12} sm={6} className={classes.heroText}>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="h3" paragraph className={classes.heroTitle}>
                Extract colors off any image with this awesome tool!
            </Typography>
              <Typography
                variant="subtitle1"
                paragraph
                className={classes.heroSubtitle}
              >
                {" "}
             This tool will fetch array of colors from an image and place them in small boxes one by one, helping you choose
             a suitable color for you. The goal is to pick colors from an image with no hassle!
              <br />
                <b>creator: Hakizimana Fred</b>
                {" "}
              </Typography>
            </Grid>
            <Grid item container justify="center" spacing={2}>

              {/* <Grid item>
              <Button
                size={matchesSm ? "small" : "large"}
                variant="outlined"
                className={classes.chromeButton}
              >
                Get it on Chrome
              </Button>
            </Grid> */}
              <Grid item>
                <TextField
                  name="imgPath"
                  type='file'
                  variant="outlined"
                  onChange={imgChange}
                />
              </Grid>
              <Grid item>

                <TextField
                  variant="outlined"
                  size="medium"
                  placeholder="Enter image url"
                  value={imageFile}
                  onChange={handleChange}

                />

                <Button
                  size={matchesSm ? "small" : "large"}
                  variant="outlined"
                  className={classes.chromeButton}
                  onClick={scrollMe}
                  style={{ padding: 15 }}
                >
                  fetch
              </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* ---image--- */}
        <Grid item container alignItems="flex-end" xs={12} sm={6}>
          <ColorExtractor getColors={getColors} >
            <img
              src={imageFile ? imageFile : IMAGE}
              alt="illustration Hero"
              className={classes.illustrationHero}
              height="550"
            />
            {/* {imageFile ? <img src={imageFile} /> : <img src={IMAGE} className={classes.illustrationHero} />} */}
          </ColorExtractor>
          {/* <div className={classes.illustrationHeroBackDiv}></div> */}

        </Grid>
      </Grid>
      <Grid item>
        <Questions renderSwatches={renderSwatches()} />
      </Grid>
    </>

  );
}
//
//
export default HeroBlock;
